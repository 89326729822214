<template>
  <b-sidebar
    id="customerForm"
    :visible="dialog"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => visibility(val)"
  >
    <template>
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 v-if="newForm" class="mb-0">
          Crear nuevo cliente
        </h5>
        <h5 v-else class="mb-0">
          <span v-if="formDisabled">Detalle de cliente</span>
          <span v-else>Editar cliente</span>
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="closeDialog"
        />
      </div>

      <!-- BODY -->
      <validation-observer ref="formData">
        <!-- Form -->
        <b-form class="p-2">
          <!-- Grupo -->
          <validation-provider #default="{ errors }" name="grupo" rules="required">
            <b-form-group
              label="Grupo"
              label-for="customer_group_id"
              :class="errors.length > 0 ? 'is-invalid':null"
            >
              <v-select 
                label="name"
                id="customer_group_id"
                v-model="formData.customer_group_id" 
                :options="customer_groups" 
                required
                :reduce="val => val.id"
                :disabled="formDisabled"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Name -->
          <validation-provider #default="{ errors }" name="nombre" rules="required">
            <b-form-group
              label="Nombre"
              label-for="name"
              :class="errors.length > 0 ? 'is-invalid':null"
            >
              <b-form-input
                id="name"
                v-model="formData.name"
                :state="errors.length > 0 ? false:null"
                trim
                :disabled="formDisabled"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Service -->
          <validation-provider #default="{ errors }" name="servicios" rules="required|mustHave:Consulta">
            <b-form-group
              label="Servicios"
              label-for="services"
              :class="errors.length > 0 ? 'is-invalid':null"
            >
              <v-select
                multiple
                label="name"
                v-model="formData.services" 
                :options="services"
                :disabled="formDisabled"
                :reduce="val => val.name"
                :class="errors.length > 0 ? 'is-invalid':null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Tipo de documento -->
          <validation-provider #default="{ errors }" name="tipo de documento" rules="required">
            <b-form-group
              label="Tipo de documento"
              label-for="document_type_id"
              :class="errors.length > 0 ? 'is-invalid':null"
            >
              <v-select
                label="name"
                v-model="formData.document_type_id" 
                :options="document_types" 
                :disabled="formDisabled"
                :reduce="val => val.id"
                :class="errors.length > 0 ? 'is-invalid':null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Documento -->
          <validation-provider #default="{ errors }" name="documento" rules="required|min:8">
            <b-form-group
              label="Documento"
              label-for="document"
              :class="errors.length > 0 ? 'is-invalid':null"
            >
              <b-form-input
                id="document"
                v-model="formData.document"
                :state="errors.length > 0 ? false:null"
                trim
                :disabled="formDisabled"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
          
          <!-- Mobile -->
          <validation-provider #default="{ errors }" name="móvil" rules="required|length:10">
            <b-form-group
              label="Móvil"
              label-for="mobile"
              :class="errors.length > 0 ? 'is-invalid':null"
            >
              <b-form-input
                id="mobile"
                type="number"
                v-model="formData.mobile"
                :state="errors.length > 0 ? false:null"
                trim
                :disabled="formDisabled"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Email -->
          <validation-provider #default="{ errors }" name="correo" rules="required|email">
            <b-form-group
              label="Correo"
              label-for="email"
              :class="errors.length > 0 ? 'is-invalid':null"
            >
              <b-form-input
                id="email"
                type="email"
                v-model="formData.email"
                :state="errors.length > 0 ? false:null"
                trim
                :disabled="formDisabled"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Departamento -->
          <validation-provider #default="{ errors }" name="departamento" rules="required">
            <b-form-group
              label="Departamento"
              label-for="state"
              :class="errors.length > 0 ? 'is-invalid':null"
            >
              <v-select 
                label="name"
                v-model="formData.state_id"
                value-field="id"
                text-field="name"
                :options="states"
                :disabled="formDisabled"
                :reduce="val => val.id"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Ciudad -->
          <validation-provider #default="{ errors }" name="ciudad" rules="required">
            <b-form-group
              label="Ciudad"
              label-for="city"
              :class="errors.length > 0 ? 'is-invalid':null"
            >
              <v-select 
                label="name"
                v-model="formData.city_id" 
                :options="cities" 
                :disabled="formDisabled"
                :reduce="val => val.id"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Mapa de Ubicación -->
          <b-form-group label="Ubicación en el mapa" label-for="map">
            <GmapMap
              :center="mapCenter"
              :zoom="14"
              style="width: 100%; height: 300px"
              @click="setLocation"
            >
              <GmapMarker v-if="markerPosition" :position="markerPosition" />
            </GmapMap>
            <small v-if="showMapError" class="text-danger">Debe seleccionar su ubicación en el mapa</small>
          </b-form-group>

          <!-- Address -->
          <validation-provider #default="{ errors }" name="dirección" rules="required">
            <b-form-group
              label="Dirección"
              label-for="address"
              :class="errors.length > 0 ? 'is-invalid':null"
            >
              <b-form-input
                id="address"
                v-model="formData.address"
                :state="errors.length > 0 ? false:null"
                trim
                :disabled="formDisabled"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Description -->
          <validation-provider #default="{ errors }" name="descripción" rules="required">
            <b-form-group
              label="Descripción"
              label-for="description"
              :class="errors.length > 0 ? 'is-invalid':null"
            >
              <b-form-textarea
                id="description"
                v-model="formData.description"
                :state="errors.length > 0 ? false:null"
                trim
                :disabled="formDisabled"
                rows="4"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Estado -->
          <validation-provider #default="{ errors }" name="estado" rules="required">
            <b-form-group
              label="Estado"
              label-for="state"
              :class="errors.length > 0 ? 'is-invalid':null"
            >
              <v-select 
                label="name"
                v-model="formData.status" 
                :options="[{id: 0, name: 'INACTIVO'},{id: 1, name: 'ACTIVO'}]" 
                :disabled="formDisabled"
                :reduce="val => val.id"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
          
          <div class="mb-5"></div>
        </b-form>
      </validation-observer>

      <!-- Botones de acción -->
      <b-button-toolbar v-if="!formDisabled" justify class="p-2">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          @click="handleSave"
        >
          Guardar
        </b-button>
        
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-secondary"
          @click="closeDialog"
        >
          Cerrar
        </b-button>
      </b-button-toolbar>
    </template>
  </b-sidebar>
</template>

<script>
  import es from 'vee-validate/dist/locale/es';
  import { ValidationProvider, ValidationObserver, localize, extend } from 'vee-validate';
  import Ripple from 'vue-ripple-directive';
  import vSelect from 'vue-select';
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
  import { gmapApi } from 'vue2-google-maps';

  import {mustHave} from '@/libs/vee-rules.js';
  extend('mustHave', mustHave);

  export default {
    name: 'CustomerForm',
    components: {
      vSelect,
      // Form Validation
      ValidationProvider,
      ValidationObserver,
    },
    directives: {
      Ripple,
    },
    computed: {
      google() {
        return gmapApi() || null;
      }
    },
    data() {
      return {
        formData: {
          id                : null,
          name              : null,
          document_type     : null,
          document          : null,
          status            : null,
          email             : null,
          mobile            : null,
          state_id          : null,
          city_id           : null,
          address           : null,
          description       : null,
          customer_group_id : null,
          lat               : null,
          lng               : null,
          services          : [],
        },
        mapCenter: { lat: 4.6482837, lng: -74.2478976 }, // Coordenadas iniciales
        markerPosition: null, // El marcador no tiene una posición inicial
        showMapError: false,
        newForm: true,
        formDisabled: false,
        dialog: false,
        document_types: [],
        customer_groups: [],
        states: [],
        cities: [],
        services: [],
      }
    },
    methods: {
      visibility(val){
        localize('es', es);
        if (!val) this.dialog = false;
      },
      closeDialog() {
        this.dialog = false;
        this.resetForm();
      },
      setLocation(event) {
        const { latLng } = event;
        const lat = latLng.lat();
        const lng = latLng.lng();
        this.markerPosition = { lat, lng };
        this.formData.lat = lat;
        this.formData.lng = lng;
        this.showMapError = false; // Ocultar el mensaje de error si se selecciona una ubicación
      },
      handleSave() {
        this.$refs.formData.validate().then(success => {
          if(success) {
            if (!this.formData.lat || !this.formData.lng) {
              this.showMapError = true;
              return;
            }
            this.showMapError = false;
            this.newForm ? this.store() : this.edit();
          }
          else{
            if (!this.formData.lat || !this.formData.lng) {
              this.showMapError = true;
              return;
            }
          }
        })
        
      },
      store() {
        localize('es', es);
        this.$refs.formData.validate().then(success => {
          if(success) {
            this.$http.post('/customers', this.formData)
            .then((response) => {
              this.dialog = false;
              this.$emit('refeshTable');
              this.resetForm();
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title   : `Cliente creado`,
                  icon    : 'CoffeeIcon',
                  variant : 'success',
                  text    : `Cliente ${response.data.customer.name} creado con éxito`,
                },
              });
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title   : `Error`,
                  icon    : 'AlertOctagonIcon',
                  variant : 'danger',
                  text    : error.response.data.message,
                },
              });
            });
          }
        });
      },
      edit() {
        localize('es', es);
        this.$refs.formData.validate().then(success => {
          if(success) {
            this.$http.put(`/customers/${this.formData.id}`, this.formData)
            .then((response) => {
                this.$emit('refeshTable');
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title   : `Cliente actualizado`,
                    icon    : 'CoffeeIcon',
                    variant : 'success',
                    text    : `Cliente actualizado con éxito`,
                  },
                });
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title   : `Error`,
                  icon    : 'CoffeeIcon',
                  variant : 'danger',
                  text    : error.response.data.message,
                },
              });
            });
          }
        });
      },
      async getCities(state_id) {
        await this.$http.get(`/states/${state_id}/cities`)
        .then(async (response) => {
          this.cities = response.data;
          const filteredCity = this.cities.find(el => el.id === this.formData.city_id); 
          this.formData.city_id = filteredCity 
                                ? this.formData.city_id 
                                : null;
        });
      },
      async getServices() {
        await this.$http.get(`/services`)
        .then(async (response) => {
          this.services = response.data;
        });
      },
      async getGroups() {
        await this.$http.get(`/groups`)
        .then(async (response) => {
          this.customer_groups = response.data.groups;
        });
      },
      resetForm() {
        this.formData = {
          id                : null,
          name              : null,
          document_type_id  : null,
          document          : null,
          status            : null,
          email             : null,
          mobile            : null,
          state_id          : null,
          city_id           : null,
          address           : null,
          description       : null,
          customer_group_id : null,
          lat               : null,
          lng               : null,
          services          : [],
        };
        this.markerPosition = null; // Restablecer el marcador al resetear el formulario
        this.showMapError = false; // Restablecer el mensaje de error del mapa
      },
    },
    watch: {
      "formData.state_id": {
        handler(val) {
          if (val) {
            this.getCities(val);
          }
        },
      },
    },
    mounted() {
      this.getServices();
      this,getGroups();
      if (this.google && this.google.maps) {
        console.log('maps ok');
      } else {
        this.$watch(
          () => this.google,
          (google) => {
            if (google && google.maps) {
              console.log('maps ok');
            }
          }
        );
      }
    }
  };
</script>
  
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>