var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"customerForm","visible":_vm.dialog,"bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","backdrop":"","no-header":"","right":""},on:{"change":function (val) { return _vm.visibility(val); }}},[[_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[(_vm.newForm)?_c('h5',{staticClass:"mb-0"},[_vm._v(" Crear nuevo cliente ")]):_c('h5',{staticClass:"mb-0"},[(_vm.formDisabled)?_c('span',[_vm._v("Detalle de cliente")]):_c('span',[_vm._v("Editar cliente")])]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":_vm.closeDialog}})],1),_c('validation-observer',{ref:"formData"},[_c('b-form',{staticClass:"p-2"},[_c('validation-provider',{attrs:{"name":"grupo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"label":"Grupo","label-for":"customer_group_id"}},[_c('v-select',{attrs:{"label":"name","id":"customer_group_id","options":_vm.customer_groups,"required":"","reduce":function (val) { return val.id; },"disabled":_vm.formDisabled},model:{value:(_vm.formData.customer_group_id),callback:function ($$v) {_vm.$set(_vm.formData, "customer_group_id", $$v)},expression:"formData.customer_group_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"name":"nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"label":"Nombre","label-for":"name"}},[_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false:null,"trim":"","disabled":_vm.formDisabled},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"name":"servicios","rules":"required|mustHave:Consulta"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"label":"Servicios","label-for":"services"}},[_c('v-select',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"multiple":"","label":"name","options":_vm.services,"disabled":_vm.formDisabled,"reduce":function (val) { return val.name; }},model:{value:(_vm.formData.services),callback:function ($$v) {_vm.$set(_vm.formData, "services", $$v)},expression:"formData.services"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"name":"tipo de documento","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"label":"Tipo de documento","label-for":"document_type_id"}},[_c('v-select',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"label":"name","options":_vm.document_types,"disabled":_vm.formDisabled,"reduce":function (val) { return val.id; }},model:{value:(_vm.formData.document_type_id),callback:function ($$v) {_vm.$set(_vm.formData, "document_type_id", $$v)},expression:"formData.document_type_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"name":"documento","rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"label":"Documento","label-for":"document"}},[_c('b-form-input',{attrs:{"id":"document","state":errors.length > 0 ? false:null,"trim":"","disabled":_vm.formDisabled},model:{value:(_vm.formData.document),callback:function ($$v) {_vm.$set(_vm.formData, "document", $$v)},expression:"formData.document"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"name":"móvil","rules":"required|length:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"label":"Móvil","label-for":"mobile"}},[_c('b-form-input',{attrs:{"id":"mobile","type":"number","state":errors.length > 0 ? false:null,"trim":"","disabled":_vm.formDisabled},model:{value:(_vm.formData.mobile),callback:function ($$v) {_vm.$set(_vm.formData, "mobile", $$v)},expression:"formData.mobile"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"name":"correo","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"label":"Correo","label-for":"email"}},[_c('b-form-input',{attrs:{"id":"email","type":"email","state":errors.length > 0 ? false:null,"trim":"","disabled":_vm.formDisabled},model:{value:(_vm.formData.email),callback:function ($$v) {_vm.$set(_vm.formData, "email", $$v)},expression:"formData.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"name":"departamento","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"label":"Departamento","label-for":"state"}},[_c('v-select',{attrs:{"label":"name","value-field":"id","text-field":"name","options":_vm.states,"disabled":_vm.formDisabled,"reduce":function (val) { return val.id; }},model:{value:(_vm.formData.state_id),callback:function ($$v) {_vm.$set(_vm.formData, "state_id", $$v)},expression:"formData.state_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"name":"ciudad","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"label":"Ciudad","label-for":"city"}},[_c('v-select',{attrs:{"label":"name","options":_vm.cities,"disabled":_vm.formDisabled,"reduce":function (val) { return val.id; }},model:{value:(_vm.formData.city_id),callback:function ($$v) {_vm.$set(_vm.formData, "city_id", $$v)},expression:"formData.city_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('b-form-group',{attrs:{"label":"Ubicación en el mapa","label-for":"map"}},[_c('GmapMap',{staticStyle:{"width":"100%","height":"300px"},attrs:{"center":_vm.mapCenter,"zoom":14},on:{"click":_vm.setLocation}},[(_vm.markerPosition)?_c('GmapMarker',{attrs:{"position":_vm.markerPosition}}):_vm._e()],1),(_vm.showMapError)?_c('small',{staticClass:"text-danger"},[_vm._v("Debe seleccionar su ubicación en el mapa")]):_vm._e()],1),_c('validation-provider',{attrs:{"name":"dirección","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"label":"Dirección","label-for":"address"}},[_c('b-form-input',{attrs:{"id":"address","state":errors.length > 0 ? false:null,"trim":"","disabled":_vm.formDisabled},model:{value:(_vm.formData.address),callback:function ($$v) {_vm.$set(_vm.formData, "address", $$v)},expression:"formData.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"name":"descripción","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"label":"Descripción","label-for":"description"}},[_c('b-form-textarea',{attrs:{"id":"description","state":errors.length > 0 ? false:null,"trim":"","disabled":_vm.formDisabled,"rows":"4"},model:{value:(_vm.formData.description),callback:function ($$v) {_vm.$set(_vm.formData, "description", $$v)},expression:"formData.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"name":"estado","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"label":"Estado","label-for":"state"}},[_c('v-select',{attrs:{"label":"name","options":[{id: 0, name: 'INACTIVO'},{id: 1, name: 'ACTIVO'}],"disabled":_vm.formDisabled,"reduce":function (val) { return val.id; }},model:{value:(_vm.formData.status),callback:function ($$v) {_vm.$set(_vm.formData, "status", $$v)},expression:"formData.status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('div',{staticClass:"mb-5"})],1)],1),(!_vm.formDisabled)?_c('b-button-toolbar',{staticClass:"p-2",attrs:{"justify":""}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary"},on:{"click":_vm.handleSave}},[_vm._v(" Guardar ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-secondary"},on:{"click":_vm.closeDialog}},[_vm._v(" Cerrar ")])],1):_vm._e()]],2)}
var staticRenderFns = []

export { render, staticRenderFns }