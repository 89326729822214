<template>
    <div>
        <b-collapse id="collapse-1">
            <b-card
            no-body
            class="mb-2"
            >
                <b-container fluid>
                    <validation-observer
                        ref="formFilters"
                    >
                        <!-- Form -->
                        <b-row class="p-1">
                            <b-col cols="12" md="3">
                                <validation-provider
                                    #default="{ errors }"
                                    name="grupo"
                                >
                                <b-form-group
                                    label="Grupo"
                                    label-for="customer_group"
                                    :class="errors.length > 0 ? 'is-invalid':null"
                                >
                                    <v-select 
                                     label="name"
                                     v-model="tableSettings.group" 
                                     :options="customer_groups"
                                     value-field="id"
                                     text-field="name"
                                     :reduce="val => val.id"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </b-form-group>
                                </validation-provider>
                            </b-col>
                            <b-col cols="12" md="3">
                                <validation-provider
                                #default="{ errors }"
                                name="estado"
                                rules="required"
                                >
                                <b-form-group
                                    label="Estado"
                                    label-for="status"
                                    :class="errors.length > 0 ? 'is-invalid':null"
                                >
                                    <v-select 
                                     label="name"
                                     v-model="tableSettings.status" 
                                     :options="[{id: 0, name: 'Inactivo'},{id: 1, name: 'Activo'}]"
                                     :reduce="val => val.id"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </b-form-group>
                                </validation-provider>
                            </b-col>
                            <b-col cols="12" md="3" >
                                <validation-provider
                                #default="{ errors }"
                                name="busqueda"
                                >
                                <b-form-group
                                    label="Busqueda"
                                    label-for="search"
                                    :class="errors.length > 0 ? 'is-invalid':null"
                                >
                                    <b-form-input
                                        id="search"
                                        trim
                                        :disabled="formDisabled"
                                        v-model="tableSettings.searchQuery" 
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </b-form-group>
                                </validation-provider>
                            </b-col>
                            <b-col cols="12" md="3">
                                <b-button variant="secondary" class="btn-icon btn-block mt-2" @click="searchFilter">
                                    <feather-icon icon="SearchIcon" /> Filtrar
                                </b-button>
                            </b-col>
                        </b-row>
                        <!-- Fin Form -->
                       
                    </validation-observer>
                </b-container>
            </b-card>
        </b-collapse>

        <b-card
        no-body
        class="mb-0"
        >
            <div class="m-2">
                <b-row>
                    <b-col cols="12" md="3">
                        <label>Mostrar</label>
                        <v-select v-model="tableSettings.perPage" :options="perPageOptions" :clearable="false"
                        class="per-page-selector d-inline-block mx-50" />
                        <label>registros</label>
                    </b-col>
                    <b-col cols="12" md="9" align="right">
                        <b-button variant="outline-secondary" v-b-toggle.collapse-1 class="btn-icon mr-1">
                            <feather-icon icon="FilterIcon" /> Filtrar
                        </b-button>
                        <b-button variant="primary" class="btn-icon" @click="createCustomer">
                            <feather-icon icon="PlusIcon" /> Crear
                        </b-button>
                    </b-col>
                </b-row>
            </div>
            <b-table
                :busy="tableSettings.busy"
                ref="customerTable"
                class="position-relative"
                :items="dataTable"
                responsive
                :fields="tableColumns"
                primary-key="id"
                :sort-by.sync="tableSettings.sortBy"
                show-empty
                empty-text="No se encontraron datos"
                :sort-desc.sync="tableSettings.sortDesc"
            >

            <!-- Column: Actions -->
            <template #cell(actions)="data">
            <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
            >

                <template #button-content>
                <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                />
                </template>
                <b-dropdown-item @click="detailCustomer(data.item)">
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Detalle</span>
                </b-dropdown-item>

                <b-dropdown-item @click="editCustomer(data.item)">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Editar</span>
                </b-dropdown-item>

                <b-dropdown-item @click="deleteCustomer(data.item)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Eliminar</span>
                </b-dropdown-item>
            </b-dropdown>
            </template>

            </b-table>

            <b-container class="mb-5">
                <b-row>
                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start"
                    >
                        <span class="text-muted">Viendo del {{ dataMeta.from }} al {{ dataMeta.to }} de {{ dataMeta.of }} registros</span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-end"
                    >
                        <b-pagination
                        v-model="tableSettings.page"
                        :total-rows="totalRows"
                        :per-page="tableSettings.perPage"
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-sm-0"
                        prev-class="prev-item"
                        next-class="next-item"
                        >
                        <template #prev-text>
                            <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                            />
                        </template>
                        <template #next-text>
                            <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                            />
                        </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </b-container>
        </b-card>
        <customer-form @refeshTable="getTableData" ref="CustomerForm"/>
    </div>
 </template>

<script>
import { 
    VBToggle
} from 'bootstrap-vue';
import CustomerForm from './CustomerForm.vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'

export default {
    name: 'Clientes',
    directives: {
        'b-toggle': VBToggle,
    },
    components: { 
        vSelect, 
        CustomerForm, 
        ValidationProvider,
        ValidationObserver
    },
    data() {
        return {
            formDisabled: false,
            perPageOptions: [10, 25, 50, 100],
            searchQuery: '',
            tableColumns: [
                { key: 'group', label: 'Grupo' },
                { key: 'name', label: 'Nombre', sortable: true },
                { key: 'doctypedoc', label: 'Documento', sortable: false },
                { key: 'state', label: 'Departamento', sortable: false },
                { key: 'city', label: 'Ciudad', sortable: false },
                { key: 'status_name', label: 'Estado', sortable: false},
                { key: 'actions', label: 'acciones' },
            ],
            sortBy: 'name',
            isSortDirDesc: true,
            totalRows: 0,
            dataMeta: {
                from: 0,
                to: 0,
                of: 0,
            },
            dataTable: [],
            tableSettings: {
                searchQuery: '',
                perPage: 10,
                page: 1,
                sortBy: null,
                sortDesc: false,
                busy: false,
                group: null,
                status: null
            },
            customer_groups: [],
            states: [],
            cities: []
        }
    },
    methods: {
        searchFilter() {
            this.getTableData();
        },
        async getCustomerGroups() {
            await this.$http.get('/customergroups')
            .then((response) => {
                this.customer_groups = response.data.customer_groups
                this.$refs.CustomerForm.customer_groups = response.data.customer_groups
            })
        },
        async getStates() {
            await this.$http.get('/states')
            .then((response) => {
                this.states = response.data
                this.$refs.CustomerForm.states = response.data
            })
        },
        async getCities(state_id) {
            await this.$http.get(`/states/${state_id}/cities`)
            .then((response) => {
                this.cities = response.data.cities
                this.$refs.CustomerForm.cities = response.data.cities
            })
        },
        //TODO: make it global
        async getDocumentTypes() {
            await this.$http.get('/documenttypes')
            .then((response) => {
                this.$refs.CustomerForm.document_types = response.data.document_types
            })
        },
        createCustomer() {
            this.$refs.CustomerForm.resetForm();
            this.$refs.CustomerForm.newForm = true;
            this.$refs.CustomerForm.formDisabled = false;
            this.$refs.CustomerForm.dialog = true;
        },
        editCustomer(customer) {
            this.$refs.CustomerForm.resetForm();
            this.$refs.CustomerForm.newForm = false;
            this.$refs.CustomerForm.formDisabled = false;
            this.$refs.CustomerForm.formData = this.fillObjFromItem(customer, this.$refs.CustomerForm.formData);
            this.$refs.CustomerForm.dialog = true;
        },
        detailCustomer(customer) {
            this.$refs.CustomerForm.resetForm();
            this.$refs.CustomerForm.newForm = false;
            this.$refs.CustomerForm.formDisabled = true;
            this.$refs.CustomerForm.formData = this.fillObjFromItem(customer, this.$refs.CustomerForm.formData)
            this.$refs.CustomerForm.dialog = true;
        },
        fillObjFromItem(itemTable, defaultObj) {
            return {
                ...defaultObj,
                ...Object.keys(itemTable).reduce((el, prop) => {
                    if (defaultObj.hasOwnProperty(prop)) {
                        el[prop] = itemTable[prop];
                    }
                    return el;
                }, {})
            }
        },
        async getTableData() {
            this.tableSettings.busy = true;
            await this.$http.get('/customers', { params: this.tableSettings }).then((response) => {
                this.dataTable = response.data.customers
                this.totalRows = response.data.total
                this.dataMetaCounter()
            })
            this.tableSettings.busy = false;
        },
        dataMetaCounter() {
            const localItemsCount = this.dataTable.length
            this.dataMeta.from = this.tableSettings.perPage * (this.tableSettings.page - 1) + (localItemsCount ? 1 : 0)
            this.dataMeta.to = this.tableSettings.perPage * (this.tableSettings.page - 1) + localItemsCount
            this.dataMeta.of = this.totalRows
        },
        deleteCustomer(customer) {
            this.$swal({
                title: `¿Está séguro de eliminar el Cliente ${customer.name}?`,
                text: "Este proceso no se puede revertir",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si, eliminar',
                cancelButtonText: 'Cancelar',
                customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            }).then(async result => {
                if (result.value) {
                    this.tableSettings.busy = true;
                    await this.$http.delete(`/customers/${customer.id}`)
                    .then(() => {
                        this.getTableData()
                        this.$swal({
                            icon: 'success',
                            title: 'Cliente eliminado',
                            text: 'El cliente ha sido eliminado.',
                            customClass: {
                                confirmButton: 'btn btn-success',
                            },
                        })
                    })
                    this.tableSettings.busy = false;
                }
            })
        },
    },
    created(){
        this.getTableData();
        this.getCustomerGroups();
        this.getStates();
        this.getDocumentTypes();
    },
    watch: {
        "tableSettings.sortBy": {
            handler(val) {
                this.getTableData()
            },
        },
        "tableSettings.sortDesc": {
            handler(val) {
                this.getTableData()
            },
        },
        "tableSettings.perPage": {
            handler(val) {
                this.getTableData()
            },
        },
        "tableSettings.page": {
            handler(val) {
                this.getTableData()
            },
        },
    }
} 
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
